import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      backUsOnKickstarter: file(relativePath: { eq: "back-us-on-kickstarter--white.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Img
      className="back-us-on-kickstarter back-us-on-kickstarter--white"
      fluid={data.backUsOnKickstarter.childImageSharp.fluid}
    />
  );
};

export default Image;
