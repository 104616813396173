import React from "react";
import PropTypes from "prop-types";

const Video = ({ video, poster, modifier }) => (
  <video
    src={video}
    type="video/mp4"
    poster={poster}
    width="375"
    className={`video video--${modifier}`}
    controls>
    <track kind="captions" srcLang="en" src="" />
  </video>
);

Video.propTypes = {
  video: PropTypes.any.isRequired,
  poster: PropTypes.any.isRequired,
  modifier: PropTypes.string.isRequired
};

Video.defaultProps = {
  modifier: 'default'
};

export default Video;