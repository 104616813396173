import React from "react";
import PropTypes from "prop-types";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import BackUsOnKickstarter from "../components/images/back-us-on-kickstarter--white";
import SecureExclusiveAccessNowOnKickstarter from "../components/images/secure-exclusive-access-on-kickstarter--white";
import MailChimpForm from "./mailchimp-form";
import IconTiles from './icon-tiles';
import HeadCameraIcon from "../assets/svg/vr-powered-live-video--head-camera.svg";
import VideoProcessingIcon from "../assets/svg/vr-powered-live-video--video-processing.svg";
import VRGlassesIcon from "../assets/svg/vr-powered-live-video--vr-glasses.svg";
import video from "../assets/video/vr-live-avatar-kickstarter-promo.mp4";
import poster from "../assets/images/vr-live-avatar-kickstarter-promo.png";
import Video from "./video";
import "./kickstarter-cta.scss";

const KICKSTARTER_PAGE_URL = "https://www.kickstarter.com/projects/daniel-ivanovic/escape-the-app-a-vr-live-video-experience-for-escape-games";

const HowItWorks = () => (
  <div className="kickstarter-cta-how-it-works">
    <h2 className="kickstarter-cta-how-it-works__heading">
      How does it work?
    </h2>
    <IconTiles
      modifier={'kickstarter-cta-how-it-works'} 
      tiles={[{
        title: '1. Recording',
        description: 'The host "avatar" wears their mobile device as a head mounted camera which films what they see.',
        icon: HeadCameraIcon
      }, {
        title: '2. Processing',
        description: 'The video is sent to our super powerful servers and re-encoded into VR video in real time.',
        icon: VideoProcessingIcon
      }, {
        title: '3. Viewing',
        description: 'Players can view the video on their mobile device without VR or with VR using any phone mounted VR headset.',
        icon: VRGlassesIcon
      }]}
    />
    <h2 className="kickstarter-cta-how-it-works__heading kickstarter-cta-how-it-works__heading--players">
      What's in it for players?
    </h2>
    <p className="kickstarter-cta-how-it-works__paragraph">
      Seeing things through the eyes of the avatar in VR video, with popup hints, a countdown timer and added sound effects, you get a sense of heightened intensity and feel more deeply immersed in the game.
    </p>
    <h2 className="kickstarter-cta-how-it-works__heading kickstarter-cta-how-it-works__heading--players">
      What's in it for owners?
    </h2>
    <p className="kickstarter-cta-how-it-works__paragraph">
      Offer your existing real-life escape rooms as live avatar experiences without having to change the game design and drammatically increase your customer base to online players all over the world.
    </p>
  </div>
);

const BackUsOnKickstarterButton = () => (
  <OutboundLink
    href={KICKSTARTER_PAGE_URL}
    target="_blank"
    rel="noreferrer"
    eventLabel="live_video_games"
    eventCategory="kickstarter"
    eventAction="kickstarter_button_click"
    className="back-us-on-kickstarter-button">
    <BackUsOnKickstarter />
  </OutboundLink>
);

const SecureExclusiveAccessNowButton = () => (
  <OutboundLink
    href={KICKSTARTER_PAGE_URL}
    target="_blank"
    rel="noreferrer"
    eventLabel="live_video_games"
    eventCategory="kickstarter"
    eventAction="secure_exclusive_access_button_click"
    className="secure-exclusive-access-on-kickstarter-button">
    <SecureExclusiveAccessNowOnKickstarter />
  </OutboundLink>
);

const KickstarterCtaTypes = {
  REGISTER_EMAIL: 'register-email',
  BACK_US_NOW: 'back-us-now'
};

const defaultProps = {
  ctaType: KickstarterCtaTypes.BACK_US_NOW,
  ctaText: 'Visit our Kickstarter campaign page now to secure your exclusive access to offer or play real-life escape rooms with VR Live Avatar.',
  showHowItWorks: false
};

const KickstarterCta = ({ modifier, showHowItWorks, ctaType, ctaText }) => (
  <div className={`section section--kickstarter-cta section--kickstarter-cta-${modifier}`}>
    <div className="section__inner section__inner--kickstarter-cta">
      <h2 className="section__heading section__heading--kickstarter-cta">
        We <span className="section__heading-heart section__heading-heart--kickstarter-cta">♥</span> Escape Games
      </h2>
      <p className="section__paragraph section__paragraph--kickstarter-cta">
        <span className="section__paragraph-highlight section__paragraph-highlight--kickstarter-cta">
          And we want to help the industry
        </span> 
        &nbsp;by providing an exciting VR powered live video solution for escape game owners to host their real-life escape games as live avatar experiences remotely to players anywhere in the world!
      </p>
      {showHowItWorks && (
        <HowItWorks />
      )}
      <h3 className="section__subheading section__subheading--kickstarter-cta">
        {ctaText}
      </h3>
      {ctaType === KickstarterCtaTypes.REGISTER_EMAIL && (
        <MailChimpForm 
          action="register_for_kickstarter"
          actionButtonLabel="Get early access"
          modifier={modifier === "blue-green" ? "white" : undefined}
        />
      )}
      {ctaType === KickstarterCtaTypes.BACK_US_NOW && (
        <SecureExclusiveAccessNowButton />
      )}
    </div>
  </div>
);

KickstarterCta.propTypes = {
  modifier: PropTypes.string.isRequired,
  ctaType: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  showHowItWorks: PropTypes.bool.isRequired
};

KickstarterCta.defaultProps = {
  ...defaultProps,
  modifier: 'vr-girl'
};

export const KickstarterCtaBlack = props => <KickstarterCta {...props} />
KickstarterCtaBlack.propTypes = { ...KickstarterCta.propTypes };
KickstarterCtaBlack.defaultProps = { ...defaultProps, modifier: 'black' };

export const KickstarterCtaGrey = props => <KickstarterCta {...props} />
KickstarterCtaGrey.propTypes = { ...KickstarterCta.propTypes };
KickstarterCtaGrey.defaultProps = { ...defaultProps, modifier: 'grey' };

export const KickstarterCtaBlueGreen = props => <KickstarterCta {...props} />
KickstarterCtaBlueGreen.propTypes = { ...KickstarterCta.propTypes };
KickstarterCtaBlueGreen.defaultProps = { ...defaultProps, modifier: 'blue-green' };

export const KickstarterRegisterEmailCta = ({ modifier }) => (
  <div className={`section section--kickstarter-register-email-cta section--kickstarter-register-email-cta-${modifier}`}>
    <div className="section__inner section__inner--kickstarter-register-email-cta">
      <Video 
        video={video}
        poster={poster}
        modifier="kickstarter-register-email-cta"
      />
      <h3 className="section__subheading section__subheading--kickstarter-register-email-cta">
        Register now for early access.
      </h3>
      <MailChimpForm 
        action="register_for_kickstarter"
        actionButtonLabel="Get early access"
        modifier={modifier === "blue-green" ? "white" : undefined}
      />
      <BackUsOnKickstarterButton />
    </div>
  </div>
);

KickstarterRegisterEmailCta.propTypes = {
  modifier: PropTypes.string.isRequired
};

KickstarterRegisterEmailCta.defaultProps = {
  modifier: 'vr-girl'
};

export const KickstarterRegisterEmailCtaBlack = props => <KickstarterRegisterEmailCta {...props} />
KickstarterRegisterEmailCtaBlack.propTypes = { ...KickstarterRegisterEmailCta.propTypes };
KickstarterRegisterEmailCtaBlack.defaultProps = { modifier: 'black' };

export const KickstarterRegisterEmailCtaBlueGreen = props => <KickstarterRegisterEmailCta {...props} />
KickstarterRegisterEmailCtaBlueGreen.propTypes = { ...KickstarterRegisterEmailCta.propTypes };
KickstarterRegisterEmailCtaBlueGreen.defaultProps = { modifier: 'blue-green' };

export const KickstarterHowItWorksCtaBlack = props => <KickstarterCta {...props} />
KickstarterHowItWorksCtaBlack.propTypes = { ...KickstarterCta.propTypes };
KickstarterHowItWorksCtaBlack.defaultProps = { ...defaultProps, showHowItWorks: true, modifier: 'black' };

export const KickstarterBackUsHowItWorksCtaBlack = props => <KickstarterCta {...props} />
KickstarterBackUsHowItWorksCtaBlack.propTypes = { ...KickstarterCta.propTypes };
KickstarterBackUsHowItWorksCtaBlack.defaultProps = { 
  ...defaultProps, 
  showHowItWorks: true, 
  ctaText: 'Visit our Kickstarter campaign page now to secure your exclusive access to offer or play real-life escape rooms with VR Live Avatar.',
  modifier: 'black' 
};

export const KickstarterBackUsNowCta = ({ modifier }) => (
  <div className={`section section--kickstarter-back-us-now-cta section--kickstarter-back-us-now-cta-${modifier}`}>
    <div className="section__inner section__inner--kickstarter-back-us-now-cta">
      <Video 
        video={video}
        poster={poster}
        modifier="kickstarter-back-us-now-cta"
      />
      <h3 className="section__subheading section__subheading--kickstarter-back-us-now-cta">
        Visit our Kickstarter campaign page now to secure your exclusive access to offer or play real-life escape rooms with VR Live Avatar.
      </h3>
      <SecureExclusiveAccessNowButton />
    </div>
  </div>
);

KickstarterBackUsNowCta.propTypes = {
  modifier: PropTypes.string.isRequired
};

KickstarterBackUsNowCta.defaultProps = {
  modifier: 'vr-girl'
};

export const KickstarterBackUsNowCtaBlueGreen = props => <KickstarterBackUsNowCta {...props} />
KickstarterBackUsNowCtaBlueGreen.propTypes = { ...KickstarterBackUsNowCta.propTypes };
KickstarterBackUsNowCtaBlueGreen.defaultProps = { modifier: 'blue-green' };

export default KickstarterCta;
