import React from "react";
import PropTypes from "prop-types";
import { map } from 'lodash';
import "./icon-tiles.scss";

const IconTiles = ({ modifier, tiles }) => (
  <div className={`icon-tiles icon-tiles--${modifier}`}>
    {map(tiles, ({ title, description, icon: IconComponent }) => (
      <div className={`icon-tiles__tile icon-tiles__tile--${modifier}`}>
        <div className={`icon-tiles__tile-icon-container icon-tiles__tile-icon-container--${modifier}`}>
          <IconComponent 
            className={`icon-tiles__tile-icon icon-tiles__tile-icon--${modifier}`}
          />
        </div>
        <h3 className={`icon-tiles__tile-title icon-tiles__tile-title--${modifier}`}>
          {title}
        </h3>
        <div className={`icon-tiles__tile-description icon-tiles__tile-description--${modifier}`}>
          {description}
        </div>
      </div>
    ))}
  </div>
);

IconTiles.propTypes = {
  modifier: PropTypes.string.isRequired,
  tiles: PropTypes.array.isRequired
};

export default IconTiles;
